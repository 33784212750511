import { format, parseISO } from 'date-fns';

import { useAuth } from '@/context/AuthContext';
import {
  HorseDto,
  FileDto,
  AssociatedDocumentFileType,
  TransactionUserProfile,
  HorseListing,
  ListingStatus,
  TransactionEventType,
} from '@/generated/core.graphql';

export const getProfileFullName = (profile: TransactionUserProfile) => {
  if (!profile) return '';

  return `${profile.firstName} ${profile.lastName}`;
};

export const formatCurrency = (value: number, precision: number = 2) => {
  // Convert to number and format as currency
  const numberValue = Number(value);
  if (!isNaN(numberValue)) {
    return `£${numberValue.toLocaleString('en-GB', {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    })}`;
  }
  return '';
};

export const formatTime = (timeStr: string): string => {
  if (!timeStr) {
    return '';
  }

  const startTime = parseISO(timeStr);
  return `${format(startTime, 'dd MMM yyyy')} ${format(startTime, 'HH:mm')}`;
};

export const formatDateAndTime = (isoDate) => {
  const date = new Date(isoDate);
  return {
    date: date.toLocaleDateString(),
    time: date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    }),
  };
};

export const calculateListingTotalPrice = (
  listingPrice: number,
  platformFeePercentage: number,
  vatPercentage: number
) => {
  const platformFee = (listingPrice * platformFeePercentage) / 100;
  const vat = (platformFee * vatPercentage) / 100;
  return listingPrice + platformFee + vat;
};

export const calculateHorseTransparencyScore = (
  horse: Partial<HorseDto>,
  horseDocuments: FileDto[],
  noBreedingCertificate = false,
  noVeterinarianHistory = false,
  noCompetitionRecords = false
) => {
  let score = 0;
  const countedDocumentTypes = new Set<AssociatedDocumentFileType>();
  const relevantDocumentTypes = [
    AssociatedDocumentFileType.BreedingCertificate,
    AssociatedDocumentFileType.VaccinationRecord,
    AssociatedDocumentFileType.VetHistory,
    AssociatedDocumentFileType.CompetitionRecord,
  ].filter((type) => {
    if (type === AssociatedDocumentFileType.BreedingCertificate)
      return !noBreedingCertificate;
    if (type === AssociatedDocumentFileType.VetHistory)
      return !noVeterinarianHistory;
    if (type === AssociatedDocumentFileType.CompetitionRecord)
      return !noCompetitionRecords;

    return true;
  });

  if (horse?.passportNumber) score += 10;
  if (horse?.transponderCode) score += 10;
  if (horse?.dam) score += 10;
  if (horse?.sire) score += 10;
  if (horse?.damSire) score += 10;

  if (noBreedingCertificate) score += 10;
  if (noVeterinarianHistory) score += 20;
  if (noCompetitionRecords) score += 10;

  horseDocuments?.forEach((file) => {
    if (
      !countedDocumentTypes.has(file.associatedDocumentFileType) &&
      relevantDocumentTypes.includes(file.associatedDocumentFileType)
    ) {
      score +=
        file.associatedDocumentFileType ===
        AssociatedDocumentFileType.VetHistory
          ? 20
          : 10;
      countedDocumentTypes.add(file.associatedDocumentFileType);
    }
  });

  return score;
};

export const checkDisallowedEvent = (
  event: TransactionEventType,
  listing: HorseListing
) => {
  const { self } = useAuth();
  const isBuyer = listing?.sellerId !== self?.id;

  if (
    listing?.status === ListingStatus.Draft ||
    (listing?.status === ListingStatus.Reserved &&
      isBuyer &&
      listing?.reservedUserId !== self?.id &&
      !allowedEventTypeWhenReserved.includes(event))
  ) {
    return true;
  }

  return false;
};

export const allowedEventTypeWhenReserved = [
  TransactionEventType.RequestDocumentViewing,
  TransactionEventType.AcceptDocumentViewing,
  TransactionEventType.RejectDocumentViewing,

  TransactionEventType.RequestPrice,
  TransactionEventType.AcceptPriceRequest,
  TransactionEventType.RejectPriceRequest,
];

export const getUpdatedData = (oldData, newData) => {
  const updatedData = {};

  for (const key in newData) {
    if (newData[key] !== oldData[key]) {
      updatedData[key] = newData[key];
    }
  }

  return updatedData;
};

export function capitalizeFirstChar(str: string): string {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function removeDashes(str: string): string {
  if (!str) return '';
  return str.replace(/-/g, ' ');
}

export function removeSpaces(str: string): string {
  if (!str) return '';
  return str.replace(/\s/g, '');
}

export function formatString(str: string): string {
  if (!str) return '';
  return capitalizeFirstChar(removeDashes(str));
}

export function formatStringCondensed(str: string): string {
  if (!str) return '';
  return capitalizeFirstChar(removeSpaces(removeDashes(str)));
}

export function ensureHttps(url: string): string {
  if (!url.startsWith('https://')) {
    return 'https://' + url;
  }
  return url;
}
